


@font-face {
    font-family: Buch;
    src: url("../../fonts/soehne-mono-buch.woff2") format("woff2"),
         url('../../fonts/soehne-mono-buch.ttf') format('truetype');
    font-weight: 400;
    font-style: normal
}

